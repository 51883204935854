import swal from 'sweetalert2';
import Utilities from '../../Utilities';

const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project_id') ? Utilities.getUrlParameter('project_id') : Utilities.getUrlParameter('project');

/**
 *
 * FUNCTIONS
 *
 */

export const init = () => {
  $(() => {
    const $adGroupDPA = $('#adset-form select#dpa');
    const $adGroupCatalog = $('#adset-form select#catalog');
    const $adGroupCatalogDiv = $('#adset-form #catalogDiv');
    const $adGroupDPADiv = $('#adset-form #dpaDiv');
    const $adGroupProductSet = $('#adset-form select#product_set');
    const $adGroupProductSetDiv = $('#adset-form #product_setDiv');
    const $adGroupProductSetSpinner = $('#adset-form .ps-spinner');
    const $adGroupPlacement = $('#adset-form select#placement');
    const $adGroupPlacementDiv = $('#adset-form #placementDiv');
    const $adGroupUseInBuildsDiv = $('#adset-form #useInBuildsDiv');
    const $adGroupSubmit = $('#adset-submit');
    const $creativePreviewDiv = $('#adset-form #creativePreviewDiv');
    const isEmailCampaign = (parseInt($('#campaign_type').val(), 10) === 4);

    /**
     *
     * INITIAL LOAD EVENTS
     *
     */

    $('[data-toggle="tooltip"]').tooltip();

    $adGroupDPA.on('change', (e) => {
      if (e.currentTarget.value === '1') {
        $adGroupProductSet.prop('disabled', true);
        $adGroupCatalogDiv.show();
        $adGroupProductSetSpinner.show();

        // If the catalog is locked then we need to go ahead and show the product sets
        if ($adGroupCatalog.prop('disabled')) {
          $adGroupProductSet.attr('required', true);
          $adGroupProductSet.prop('disabled', true);
          $adGroupProductSetSpinner.show();
          $adGroupProductSetDiv.show();

          const catalogId = $adGroupCatalog.val();

          $.ajax({
            url: `/facebook/product-sets/get/process?catalog_id=${catalogId}&campaign_id=${campaignID}`,
            type: 'post',
          })
            .done((rawData) => {
              const data = JSON.parse(rawData);

              if (data.success) {
                let html = '';
                const { productSets } = data;

                Object.keys(productSets).forEach((key) => {
                  const { id, name } = productSets[key];
                  let nameForOption = name;
                  if (nameForOption.length > 25) {
                    nameForOption = nameForOption.substring(0, 25) + "... ";
                  }
                  html += `<option value="${id}" >${nameForOption} (${id})</option>`;
                });

                $adGroupProductSet.html(html);
                $adGroupProductSet.prop('disabled', false);
                $adGroupSubmit.prop('disabled', false);
              } else {
                swal.fire({
                  icon: 'error',
                  title: data.message,
                  timer: 5000,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then();
              }

              $adGroupProductSetSpinner.hide();
              $('#adgroup-submit').prop('disabled', false);
            })
            .fail((jqXHR, status, error) => {
              console.log(error);
            });
        }
      } else {
        $adGroupSubmit.prop('disabled', false);
        $adGroupCatalogDiv.hide();
        $adGroupCatalog.val('');
        $adGroupCatalog.selectpicker('refresh');
        $adGroupProductSet.attr('required', false);
        $adGroupProductSet.val('');
        $adGroupProductSetDiv.hide();
      }
    });

    $adGroupPlacement.on('change', (e) => {
      const selectedValue = e.currentTarget.value;

      if (selectedValue !== 'fb_stories' && selectedValue !== 'email_automation' && selectedValue !== 'email_broadcast') {
        $adGroupDPADiv.show();
        // Trigger the change events for the lower items so that we get them back if necessary
        $adGroupDPA.trigger('change');
      } else {
        $adGroupDPADiv.hide();
        $adGroupCatalogDiv.hide();
        $adGroupProductSetDiv.hide();
      }
    });

    $adGroupCatalog.on('change', (e) => {
      $adGroupProductSetDiv.show();
      $adGroupProductSetSpinner.show();
      $adGroupProductSet.prop('disabled', true);
      $adGroupSubmit.prop('disabled', true);

      const catalogId = e.currentTarget.value;

      $.ajax({
        url: `/facebook/product-sets/get/process?catalog_id=${catalogId}&campaign_id=${campaignID}`,
        type: 'post',
      })
        .done((rawData) => {
          const data = JSON.parse(rawData);

          if (data.success) {
            let html = '';
            const { productSets } = data;

            Object.keys(productSets).forEach((key) => {
              const { id, name } = productSets[key];
              let nameForOption = name;
              if (nameForOption.length > 25) {
                nameForOption = nameForOption.substring(0, 25) + "... ";
              }
              html += `<option value="${id}" >${nameForOption} (${id})</option>`;
            });

            $adGroupProductSet.html(html);
            $adGroupProductSet.prop('disabled', false);
            $adGroupSubmit.prop('disabled', false);
          } else {
            swal.fire({
              icon: 'error',
              title: data.message,
              timer: 5000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then();
          }

          $adGroupProductSetSpinner.hide();
          $('#adgroup-submit').prop('disabled', false);
        })
        .fail((jqXHR, status, error) => {
          console.log(error);
        });
    });

    $('#adsets .sortable').sortable({ placeholderClass: 'list-group-item clearfix adsetlink' }).bind('sortupdate', () => {
      const sortOrder = [];
      $('.sortable a').each(function processSortable() {
        sortOrder.push($(this).data('adset'));
      });
      const sortOrderString = sortOrder.join(',');

      $.ajax({
        url: `/campaign/edit/process?project_id=${projectID}&campaign=${campaignID}`, type: 'get', data: { sortadsets: sortOrderString }, dataType: 'json',
      })
        .done((data) => {
          if (!data.success) {
            swal.fire({
              icon: 'error',
              title: data.message,
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              $('#loading-overlay').hide();
            });
          }
        })
        .fail((jqXHR, status, error) => {
          swal.fire({
            icon: 'error',
            title: error,
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => {
            $('#loading-overlay').hide();
          });
        });
    });

    $('#adset-form #want-utm').on('change', (e) => {
      $('#adset-form #utm_block').css('display', $('#adset-form #want-utm').prop('checked') ? 'block' : 'none');

      if ($(`#adset-form #custom_adset_toggle`).prop('checked')) {
        $('#adset-form #utm-inputs').css('display', 'none');
        $('#adset-form #custom_block').css('display', 'block');
        $('#adset-form #use_custom_utm').val('1');
      } else {
        $('#adset-form #utm-inputs').css('display', 'block');
        $('#adset-form #custom_block').css('display', 'none');
        $('#adset-form #use_custom_utm').val('');
      }
    });

    $('#custom_adset_toggle').on('change', (e) => {
      if ($(`#custom_adset_toggle`).prop('checked')) {
        $('#adset-form #utm-inputs').css('display', 'none');
        $('#adset-form #custom_block').css('display', 'block');
        $('#adset-form #use_custom_utm').val('1');
      } else {
        $('#adset-form #utm-inputs').css('display', 'block');
        $('#adset-form #custom_block').css('display', 'none');
        $('#adset-form #use_custom_utm').val('');
      }
    });

    $('[id^=adsetlink]').on('click', (event) => {
      event.preventDefault();
      $('.loader, #loading-overlay').show();
      $('#adset-form').trigger('reset');
      $('#adset-new').val('0');
      $('#adset-form #use_custom_utm').val('');
      $adGroupSubmit.html('Update Adset');
      const adsetId = $(event.currentTarget).data('adset');
      $('#adset_id').val(adsetId);

      // Reset our dropdown visibility
      $adGroupCatalogDiv.hide();
      $adGroupProductSetDiv.hide();
      $adGroupDPADiv.show();
      $adGroupPlacementDiv.show();
      $adGroupUseInBuildsDiv.show();
      $creativePreviewDiv.show();
      $('#adset-form #utm_block').hide();

      $.ajax({ url: `/campaign/edit/adset/get/process?project_id=${projectID}&campaign=${campaignID}&adset=${adsetId}`, dataType: 'json' })
        .done((data) => {
          const {
            message,
            adset_id: adsetID,
            name,
            label,
            active,
            creative_previews: creativePreviews,
            placement,
            is_dpa: isDPA,
            fb_catalog_id: fbCatalogID,
            fb_product_set_id: fbProductSetID,
            placementIsEditable,
            use_in_builds: useInBuilds,
            use_utm: useUtm,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_term: utmTerm,
            utm_content: utmContent,
            utm_custom: utmCustom,
          } = data;

          if (adsetID) {
            $adGroupDPA.prop('disabled', true);

            if (isDPA === '1') {
              $adGroupDPA.val('1');
              $adGroupCatalogDiv.show();
              $adGroupProductSetSpinner.show();

              $adGroupCatalog.val(fbCatalogID).prop('disabled', true);
              $adGroupCatalog.selectpicker('refresh');

              $adGroupProductSetDiv.show();
              $adGroupProductSetSpinner.show();
              $adGroupProductSet.prop('disabled', true);
              $adGroupSubmit.prop('disabled', true);

              $.ajax({
                url: `/facebook/product-sets/get/process?catalog_id=${fbCatalogID}&campaign_id=${campaignID}`,
                type: 'post',
              })
                .done((rawData) => {
                  const catalogProductSetsData = JSON.parse(rawData);

                  if (catalogProductSetsData.success) {
                    let html = '';
                    const { productSets } = catalogProductSetsData;

                    Object.keys(productSets).forEach((key) => {
                      const { id, name: productSetName } = productSets[key];
                      let nameForOption = productSetName;
                      if (nameForOption.length > 25) {
                        nameForOption = nameForOption.substring(0, 25) + "... ";
                      }
                      html += `<option value="${id}" >${nameForOption} (${id})</option>`;
                    });

                    $adGroupProductSet.html(html).val(fbProductSetID);
                  } else {
                    swal.fire({
                      icon: 'error',
                      title: catalogProductSetsData.message,
                      timer: 5000,
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                    }).then();
                  }

                  $adGroupProductSetSpinner.hide();
                  $adGroupSubmit.prop('disabled', false);
                  $('#essentials-form').trigger('reinitialize.areYouSure'); // this refresh is causing info form to trigger a change?
                })
                .fail((jqXHR, status, error) => {
                  console.log(error);
                });
            }

            $('#adset_name').val(name);
            $('#adset_label').val(label);
            $('#adset_status').val(active);
            $('#creative_previews_adset').val(creativePreviews);
            $('#use_in_builds').val(useInBuilds);
            $adGroupPlacement.val(placement);

            if (placementIsEditable === false) {
              $adGroupPlacement.prop('disabled', true);
            }

            // Email adsets use a stripped down form
            if (isEmailCampaign) {
              $adGroupDPADiv.hide();
              $adGroupUseInBuildsDiv.hide();
              $creativePreviewDiv.hide();
            }

            if (useUtm === '1') {
              $('#want-utm').prop('checked', true).trigger('change');

              if (utmCustom) {
                $('#custom_adset_toggle').prop('checked', true).trigger('change');
              }
            }

            $('#adset-form #custom_input').val(utmCustom);
            $('#adset-form #utm_source').val(utmSource ?? '{{site_source_name}}');
            $('#adset-form #utm_medium').val(utmMedium ?? '{{placement}}');
            $('#adset-form #utm_campaign').val(utmCampaign ?? '{{campaign.id}}');
            $('#adset-form #utm_term').val(utmTerm ?? 'mnk');
            $('#adset-form #utm_content').val(utmContent ?? '{{ad.id}}');

            $('.loader, #loading-overlay').hide();
            $('#adsetModal').appendTo('body').modal('show');
          } else {
            $('.loader').hide();
            swal.fire({
              icon: 'error',
              title: message,
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              $('#loading-overlay').hide();
            });
          }
        })
        .fail((jqXHR, status, error) => {
          $('.loader').hide();
          swal.fire({
            icon: 'error',
            title: error,
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => {
            $('#loading-overlay').hide();
          });
        });
    });

    $('#addnew-adset').on('click', (event) => {
      event.preventDefault();

      // Reset our dropdown visibility
      $adGroupCatalogDiv.hide();
      $adGroupProductSetDiv.hide();
      $adGroupDPADiv.show();
      $adGroupPlacementDiv.show();
      $adGroupUseInBuildsDiv.show();
      $('#adset-form #utm_block').hide();

      // Start resetting our form
      $('#adset-form').trigger('reset');

      // Now that we've reset the form the catalog that may have been locked was reset so we need to update the selected value again
      $adGroupCatalog.val($('#essentials-form select#fb_catalog_id').val());

      $('#adset-form #use_custom_utm').val('');
      $('#adset-new').val('1');
      $('#adset_id').val('new');
      $adGroupPlacement.prop('disabled', false);
      $adGroupDPA.prop('disabled', false);
      $adGroupProductSet.prop('disabled', false);
      $adGroupCatalog.selectpicker('refresh');
      $adGroupSubmit.html('Create Adset');

      // Email adsets use a stripped down form
      if (isEmailCampaign) {
        $adGroupDPADiv.hide();
        $adGroupUseInBuildsDiv.hide();
        $creativePreviewDiv.hide();

        $adGroupDPA.val('0');
        $('#use_in_builds').val('1');
      }

      $('#adsetModal').appendTo('body').modal('show');
    });

    $('#adset-form').on('submit', (event) => {
      event.preventDefault();

      $('.loader, #loading-overlay').show();
      $adGroupSubmit.addClass('btn-waiting').prop('disabled', true);
      $adGroupPlacement.prop('disabled', false);
      const adsetId = $('#adset_id').val();
      $.ajax({
        url: `/campaign/adset/edit/process?project_id=${projectID}&campaign=${campaignID}&adset=${adsetId}`, type: 'get', data: $('#adset-form').serialize(), dataType: 'json',
      })
        .done((data) => {
          $adGroupSubmit.removeClass('btn-waiting').prop('disabled', false);

          if (data.success) {
            if ($('#adset-new').val() === '1') {
              window.location.reload();
            } else {
              $(`#adset-name-${adsetId}`).html(`${$('#adset_label').val()} - ${$('#adset_name').val()}`);

              if ($('select[name=adset_status] option:selected').val() === '1') {
                $(`#adset-disabled-${adsetId}`).hide();
              } else {
                $(`#adset-disabled-${adsetId}`).show();
              }
              $('#adsetModal').modal('hide');

              $('.loader').hide();

              swal.fire({
                icon: 'success',
                title: 'Adset Updated',
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                $('#loading-overlay').hide();
              });

              if ($('#use_in_builds').val() === '1') {
                $(`#adsetlink-${adsetId} span`).remove('.status-dot');
              } else {
                $(`#adsetlink-${adsetId} span:first-child`).prepend('<span class="status-dot inactive" title="Do not use in Builds!"></span>');
              }
            }
          } else {
            $('.loader').hide();

            swal.fire({
              icon: 'error',
              title: data.message,
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              $('#loading-overlay').hide();
            });
          }
        })
        .fail((jqXHR, status, error) => {
          $adGroupSubmit.removeClass('btn-waiting').prop('disabled', false);
          $('.loader').hide();

          swal.fire({
            icon: 'error',
            title: error,
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => {
            $('#loading-overlay').hide();
          });
        });
    });

    /** INITIAL LOAD EVENTS -- END */

    /**
     *
     * EVENT TRIGGER HANDLERS
     *
     */

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
